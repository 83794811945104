.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-color: #f4f7f6; /* Light grey background */
  }
  
  .welcome-message {
    text-align: center;
    margin-bottom: 30px; /* Adjust as necessary */
  }
  
  .welcome-message h1 {
    color: #333; /* Dark grey color for text */
    font-size: 2.5rem; /* Large text */
    margin-bottom: 0.5rem;
  }
  
  .welcome-message p {
    color: #555; /* Medium grey color for text */
    font-size: 1.2rem; /* Medium text */
  }
  
  .auth-form-container {
    width: 100%;
    max-width: 400px; /* Maximum width of forms */
    background: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border-radius: 8px; /* Rounded corners for the form containers */
  }
  
  .switch-view {
    display: flex;
    justify-content: space-around;
    margin-top: 20px; /* Space above the switch view buttons */
  }
  
  .switch-view button {
    background-color: #007bff; /* Green background for buttons */
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 4px; /* Rounded corners for buttons */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth background color transition */
  }
  
  .switch-view button:hover {
    background-color: #007bff; /* Darker green on hover */
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .auth-form-container {
      padding: 15px;
    }
    .welcome-message h1 {
      font-size: 2rem;
    }
    .welcome-message p {
      font-size: 1rem;
    }
  }
  