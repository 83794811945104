.logout-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f4f7f6; /* Replace with your actual background color */
    color: #333; /* Replace with your actual text color */
    padding: 20px;
    border-radius: 8px;
  }
  
  .logout-container button {
    padding: 10px 20px;
    background-color: #007bff; /* Replace with your actual button background color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .logout-container p {
    font-size: 1.2rem; /* Increase the font size for readability */
    text-align: center; /* Center the text */
    margin-top: 20px;
}
  