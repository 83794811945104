.message-input {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid #d0d0d0;
}

.message-input input {
    width: 90%;
    padding: 5px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
}

.message-input button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
}
