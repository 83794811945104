.nav-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    text-decoration: none;  /* Remove underline */
    color: #ffffff;         /* Set text color to white */
}

.nav-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.nav-item-icon {
    width: 20px;
    margin-right: 10px;
    color: rgb(255, 255, 255); 
    align-items: center;
}

