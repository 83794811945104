.side-nav {
    width: 20vh;
    transition: width 0.3s;
    background-color: #007bff;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.side-nav.collapsed {
    width: 8vh; /* width of icons */
}

.nav-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.nav-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.nav-item img {
    width: 40px; 
    margin-right: 10px;
}

.toggle-btn {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%); 
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center; 
    justify-content: center;
}

.toggle-btn svg {
    color: rgb(255,255,255);
    width: 24px;  
    height: 24px;  
}


.side-nav:not(.collapsed) .toggle-btn {
    left: auto;
    right: 10px;
    transform: none;
}

.logo {
    display: flex;
    cursor: pointer;
    text-decoration: none;  
    color: #ffffff;     
    width: 40px; /* specify the width of the logo */
    height: 40px; /* specify the height of the logo */

}

.logo-link {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;  
    color: #ffffff;
    width: 40px; /* specify the width of the logo */
    height: 40px; /* specify the height of the logo */
}

.logo-container {
    display: flex;
    align-items: center;
    padding-top: 50px;
}

.logo-text {
    margin-left: 10px; /* adjust as needed */
}

.logo-text.hidden {
    display: none;
}

.nav-item-divider {
    height: 1px;
    width: 100%;
    margin: 30px 0;
}