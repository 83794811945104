
.sidebar {
    width: 20%;
    overflow-y: auto;
    background-color: #f4f7f6;
    padding: 20px;
    box-shadow: 2px 0px 5px rgba(0,0,0,0.1);
    font-family: Arial, sans-serif;
    flex: column;
    flex-direction: column;
    display: flex;
    
}

.patient-information {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
}
.info-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .info-item label {
    font-weight: bold;
    color: #333;
    font-size: 14px;
  }
  
.info-item span {
    font-size: 16px;
    color: #666;
  }
  .conclude-visit-button {
    margin-top: auto;        /* Push the button to the bottom */
    padding: 10px;
    background-color: #007bff; /* Adjust the color as per your design */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}

.conclude-visit-button:hover {
    background-color: #007bff; /* Adjust the hover color as per your design */
}