.profile-menu {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding: 0 20px;
}

.icons {
    margin-right: 20px;
    /* Add styles for icons, spacing, etc. */
}

.profile-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.dropdown-btn {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

.profile-menu {
    position: relative; /* Needed for positioning the dropdown */

}

.profile-menu {
    position: relative; /* Needed for positioning the dropdown */
    display: inline-block; /* Display inline-block instead of block */
    font-family: 'Arial', sans-serif; /* Choose a modern font */
}

.dropdown-btn {
    /* Style as needed for the dropdown button */
    cursor: pointer;
    background-color: #ffffff; /* Match the button color to the mockup */
    border: none; /* Remove border for a clean look */
    padding: 8px; /* Adjust padding as needed */
    border-radius: 4px; /* Rounded corners for the button */
}

.dropdown-content {
    display: none;
    z-index: 1; /* Ensure it's on top of other elements */
    background-color: #f9f9f9; /* Match background color to the mockup */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    padding: 12px 0px; /* Some padding for content */
    border-radius: 4px; /* Rounded corners */
    border: 1px solid #e1e4e8; /* Light border as seen in the mockup */
    width: 78%;
    position: absolute; /* Position the dropdown */

}

/* Display the dropdown content when the dropdown is open */
.profile-menu .dropdown-content.show {
    display: inline-block;
    z-index: 1; /* Ensure it's on top of other elements */
}

/* Style the links or items within the dropdown */
.dropdown-content a {
    text-decoration: none; /* Remove underline from links */
    color: #333333; /* Color for the text */
    padding: 8px 16px; /* Padding for each item */
    display: block; /* Make each item block to occupy full width */
    transition: background-color 0.2s; /* Smooth transition for hover effect */
}

/* Hover effect for dropdown items */
.dropdown-content a:hover {
    background-color: #f2f2f2; /* Slightly different background on hover */
}

.dropdown-content.show {
    display: inline-block;
}


/* General button styling for a modern look */
.profile-menu .sign-in-btn {
    background-color: #ffffff; /* White background for the button */
    color: #333333; /* Dark text color for contrast */
    border: 1px solid #cccccc; /* Light border for definition */
    padding: 10px 20px; /* Padding inside the button */
    border-radius: 5px; /* Rounded corners for modern look */
    font-size: 0.9rem; /* Font size for the button text */
    font-weight: bold; /* Bold font for the text */
    text-transform: uppercase; /* Uppercase text for stylistic choice */
    outline: none; /* Remove default outline */
    cursor: pointer; /* Change cursor to pointer to indicate a clickable element */
    display: block; /* Display block to ensure the button takes full width */
    width: 100%; /* Full width of its parent */
    box-sizing: border-box; /* Include padding and border in the width calculation */
    margin-bottom: 8px; /* Margin at the bottom of each button for spacing */
    transition: background-color 0.3s, border-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

/* Remove margin from the last button */
.profile-menu .sign-in-btn:last-child {
    margin-bottom: 0; /* No margin for the last button to avoid extra space at the end */
}

/* Hover and focus states for interactivity */
.profile-menu .sign-in-btn:hover,
.profile-menu .sign-in-btn:focus {
    background-color: #f2f2f2; /* Light grey background on hover/focus */
    border-color: #aaaaaa; /* Darker border on hover/focus */
    color: #000000; /* Darker text on hover/focus */
}

/* Active state for when the button is clicked */
.profile-menu .sign-in-btn:active {
    background-color: #e2e2e2; /* Even darker background when active */
    border-color: #888888; /* Even darker border when active */
}

/* Optional: Disabled state for buttons */
.profile-menu .sign-in-btn:disabled {
    background-color: #eeeeee; /* Light background for disabled button */
    color: #aaaaaa; /* Light text for disabled button */
    border-color: #dddddd; /* Light border for disabled button */
    cursor: not-allowed; /* Cursor to indicate the button is not interactive */
}

.profile-button span {
    margin-left: 10px; /* Add space to the left of the name */
    vertical-align: middle; /* Align the name with the middle of the button */
    font-size: 16px; /* Adjust the font size */
    color: #ffffff; /* Adjust the color */
    margin-left: 10px;
}

.profile-button {
    display: flex; /* Align items in a row */
    align-items: center; /* Center items vertically */
    width: 100%; /* Full width of its parent */
    /* Other styles... */
}

.dropdown-item {
    background-color: transparent!important;
    border: none!important;
    padding: 10px!important;
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    outline: none;
}

.dropdown-item:hover {
    background-color: #e2e2e2!important;
}
