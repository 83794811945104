.visit-chat {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border-left: 1px solid #d1d1d1;
    background-color: #f5f5f5;
}

/* Add a flex grow property to the chat messages container to make it take available space */
.chat-messages {
    flex-grow: 1;
    overflow-y: auto;
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    background-color: #e5e5e5;
    padding: 20px 24px;
}

.message {
    padding: 8px 12px;
    margin: 5px 0;
    border-radius: 4px;
    background-color: #f1f1f1; /* Light gray background */
}


.chat-message {
    margin: 5px;
    padding: 8px 12px;
    border-radius: 12px;
    max-width: 60%;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user {
    background-color: #AED6F1; /* Light blue, you can adjust to your liking */
    align-self: flex-end;
    margin-right: 10px;
    align-self: flex-end;
}

.assistant {
    background-color: #F2F3F4; /* Light gray, you can adjust to your liking */
    align-self: flex-start;
    margin-left: 10px;
}


.loading-dots span {
    /* Styles for your loading dots */
    display: inline-block;
    margin-right: 2px;
    border-radius: 50%;
    animation: loadingAnimation 1s infinite;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background-color: #ccc;
}

@keyframes loadingAnimation {
    0%, 80%, 100% {
        transform: scale(0);
    } 
    40% {
        transform: scale(1.0);
    }
}



.chat-header {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 1px solid #d1d1d1;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    padding: 20px 24px;
}

.chat-name {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.chat-message.user {
    align-self: flex-end;
    background-color: #dbebff;  /* Light blue */
    color: #333;
}

.chat-message.assistant {
    background-color: #f7f7f7;  /* Light gray */
    color: #333;
}

.message-content {
    word-wrap: break-word;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.4;
}















