.loading-spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #f1f1f1;
    padding: 1rem;
  }
  

  .loading-spinner h2 {
    color: #007bff;
    margin-bottom: 0.5rem;
  }


  .visit-summary {
    background-color: #f5f5f5; /* dark blue color from the image */
    color: #666; /* light grey for text */
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.visit-summary h2 {
    color: #666; /* white for headers */
    border-bottom: 1px solid #007bff; /* light blue color for line under headers */
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.visit-summary p {
    margin-bottom: 10px;
}

.visit-summary button {
    background-color: #007bff; /* light blue for button */
    color: #FFFFFF; /* white text for button */
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.visit-summary button:hover {
    background-color: #007bff;
}

.loading-spinner h2 {
    color: #FFFFFF; /* white for headers */
}

.loading-spinner {
    text-align: center;
    color: #E0E0E0; /* light grey for text */
}
