/* VisitFilters.css */

.visit-filters-container {
    /* Use the colors, fonts, and other styles from your screenshot */
    background-color: #f3f4f6; /* Example color */
    color: #333; /* Example color */
    font-family: 'Your Font Family', sans-serif; /* Replace with your actual font family */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Example shadow */
    display: flex;
    justify-content: stretch; /* This will make child elements fill the space */
    align-items: stretch; /* This ensures children stretch to fill the container's height */
    height: 100%; /* Set to the desired height or min-height */
    margin: 0; /* Remove margins if any */
    padding: 0; /* Remove paddings if any */
  }

  .filters-instructions-layout {
    display: flex;
    flex-grow: 1; /* This will make the layout element grow to fill the container */
    width: 100%; /* Ensures the layout takes full width */
    gap: 20px; /* Adjust the space between the two sections */
  }

  .filters-section, .instructions-section {
    flex: 1; /* This will allow both sections to grow and fill the available space equally */
    padding: 20px; /* Add padding if needed */
    /* Rest of the styles */
  }
  
  .filters-section {
    flex: 1;
    /* ... (additional styling if needed) */
  }
  
  .instructions-section {
    flex: 1;
    background-color: #f9f9f9; /* Light background for contrast */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .instructions-section h2 {
    color: #333; /* Example heading color */
    margin-bottom: 10px;
  }
  
  .instructions-section p, .instructions-section ol {
    color: #666; /* Example text color */
    margin-bottom: 10px;
  }
  
  .instructions-section ol {
    padding-left: 20px;
  }
  
  
  .visit-filters-form label {
    display: block;
    margin-bottom: 15px;
    font-size: 1rem; /* Example font size */
  }
  
  .visit-filters-form .multi-select-filter {
    /* Style for your MultiSelectFilter component, if necessary */
  }
  
  .spacer {
    margin: 20px 0; /* Adjust spacing as per your layout */
  }
  
  .start-visit-button {
    background-color: #4a90e2; /* Example button color */
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .start-visit-button:hover {
    background-color: #3a7bd5; /* Darker shade for hover effect */
  }
  

@media (max-width: 768px) {
    .filters-instructions-layout {
      flex-direction: column;
    }
  
    .filters-section,
    .instructions-section {
      flex: none;
      width: 100%;
      margin-bottom: 20px;
    }
  }