.layout-container {
    display: flex;
    height: 100vh;
    flex-direction: row;
}

.side-nav {
    background-color: #007bff;
    width: 20vh; 
    height: 100vh; 
    overflow: auto;
}

.right-content {
    flex: 1;
    display: flex;
    flex-direction: column;   /* Stacks Header, main-content, and Footer vertically */
    width: 100%;  /* take the full width of its parent */
    overflow: hidden;  /* prevent overflow */
}

.main-content {
    flex-grow: 1;  /* Allow main content to fill available space */
    flex: 1;
    display: flex;
    flex-direction: column;  /* Stacks Header, main-content, and Footer vertically */
    overflow-y: auto;
    box-sizing: border-box; /* Include padding in height calculation */
    background-color: #f4f7f6;
}