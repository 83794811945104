.confirm-signup-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: white;
  }
  
  .confirm-signup-container h2 {
    text-align: center;
  }
  
  .confirm-signup-container form {
    display: flex;
    flex-direction: column;
  }
  
  .confirm-signup-container input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .confirm-signup-container button {
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .confirm-signup-container button:hover {
    background-color: #0056b3;
  }
  
  .confirm-signup-container button:active {
    background-color: #003580;
  }
  
  .confirm-signup-container button:nth-child(2) {
    background-color: #6c757d;
  }
  
  .confirm-signup-container button:nth-child(2):hover {
    background-color: #5a6268;
  }
  
  .confirm-signup-container button:nth-child(2):active {
    background-color: #343a40;
  }
  