/* ResetPassword.css */
.reset-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.reset-password-title {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 20px;
}

.reset-password-form {
    width: 100%;
    max-width: 350px;
}

.reset-password-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-sizing: border-box;
}

.reset-password-form button {
    width: 100%;
    padding: 12px;
    background-color: #007bff; /* Orange background */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
}

.reset-password-form button:hover {
    background-color: #007bff; /* Darker orange on hover */
}

.reset-password-form .sign-in-link {
    display: block;
    text-align: center;
    color: #555;
    text-decoration: none;
    margin-top: 10px;
}

.reset-password-form .sign-in-link:hover {
    text-decoration: underline;
}

.error-message {
    color: red;
    margin-top: 10px;
    text-align: center;
}

.success-message {
    color: green;
    margin-top: 10px;
    text-align: center;
}

.sign-in-link {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}