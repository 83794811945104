.container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100vh;
  }
  
  .visit-filters {
    width: 100%;
    height: 100%;
  }
  
  .chat-app {
    width: 100%;
    height: 100%;
  }

  .visit-summary {
    display: flex
  }
  
  .placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f1f1f1;
    padding: 1rem;
  }
  
  .placeholder h2 {
    color: #007bff;
    margin-bottom: 0.5rem;
  }
  
  .placeholder p {
    color: #007bff;
    max-width: 66%;
    padding: 0.5rem 1rem;
    text-align: center;
    font-size: 1rem;
    line-height: 1.4;
  }
  

  .loading-spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f1f1f1;
    padding: 1rem;
  }
  

  .loading-spinner h2 {
    color: #007bff;
    margin-bottom: 0.5rem;
  }